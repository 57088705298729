const TestimonialData = [
  {
    id: 1,
    name: "Sujal Bora",
    msg: "Choices of Nourishment: Rave Reviews from Our Culinary Community$Love on a Plate: Heartwarming Testimonials from Mother's Touch$Kafeoices of Nourishment: Rave Reviews from Our Culinary Community",
    rating: 3,
  },
  {
    id: 2,
    name: "Bora",
    msg: "Choices of Nourishment: Rave Reviews from Our Culinary Community$Love on a Plate: Heartwarming Testimonials from Mother's Touch$Kafeoices of Nourishment: Rave Reviews from Our Culinary Community",
    rating: 5,
  },
];

export { TestimonialData };
