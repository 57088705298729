import React from "react";
import "./ImageShimmer.css";

export const ImageShimmer = () => {
  return (
    <div className="img-shimmer-cntrr">
      <div className="img-ph-cntr"></div>
    </div>
  );
};
