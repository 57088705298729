import React from "react";
import "./MtFooter.css";

export default function MtFooter() {
  return (
    <div className="footer">
      <p>2023 Copyright. www.binaryspace.com</p>
    </div>
  );
}
